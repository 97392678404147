import Vue from 'vue'
import axios from 'axios'
import { apiPath } from './../../config'

export default {
  namespaced: false,
  state: () => {
    return {
      config: {}
    }
  },
  mutations: {
    findShopConfigResult: (state, { item, id }) => {
      if (item.logoAssetId) {
        item.logoAssetId = [{
          uri: item.logoAssetId,
          type: 'logo'
        }]
      }
      Vue.set(state.config, id, item)
    }
  },
  getters: {},
  actions: {
    findShopConfig: ({ commit, state: { config } }, { id, lazy = false }) => {
      if (config[id] != null && lazy) return Promise.resolve(config[id])
      return new Promise((resolve, reject) => {
        axios.get(`${apiPath}/shop/${id}/config`)
          .then(({ data }) => {
            commit('findShopConfigResult', { item: data, id })
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveShopConfig: ({ commit, getters: { affiliateId } }, { title, description, font, primaryColor, bgColor, logoAssetId, logoWidth, logoHeight, questions, logoSize, affiliateTerms, affiliatePrivacy, seriesDisplayMode, tracking, robotsNoindex, reducedCustomerData }) => {
      return axios.put(`${apiPath}/shop/${affiliateId}/config`, { title, description, font, primaryColor, bgColor, logoAssetId, logoWidth, logoHeight, questions, logoSize, affiliateTerms, affiliatePrivacy, seriesDisplayMode, tracking, robotsNoindex, reducedCustomerData })
        .then(({ data }) => {
          commit('findShopConfigResult', { item: data, id: affiliateId })
        }).catch((error) => {
          throw error
        })
    }
  }
}
