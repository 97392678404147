import { v4 as uuidv4 } from 'uuid'

export function fee () {
  return {
    ticketFeeVariable: null,
    ticketFeeFix: null,
    ticketFee: null,
    outletFeeVariable: null,
    outletFeeFix: null,
    outletFee: null,
    affiliateFeeVariable: null,
    affiliateFeeFix: null,
    affiliateFee: null,
    basePrice: null,
    basePriceAffiliate: null,
    grossPrice: null
  }
}

export function fees () {
  return {
    shop: { ...fee() },
    tdl: { ...fee() },
    affiliate: { ...fee() }
  }
}

export function facebook () {
  return {
    eventId: null,
    pageId: null,
    title: null,
    venue: null,
    category: null,
    description: null,
    assetId: null,
    startDate: null,
    isCanceled: null
  }
}

export function ticketType () {
  return {
    id: null,
    uid: uuidv4(),
    name: null,
    price: null,
    contingent: null,
    startDate: null,
    endDate: null,
    free: false,
    reference: `ref_${uuidv4()}`,
    channels: {
      shop: true,
      tdl: true,
      affiliate: true
    },
    fees: fees()
  }
}

export function ticketArea () {
  return {
    id: null,
    uid: uuidv4(),
    reference: `ref_${uuidv4()}`,
    name: '',
    contingent: '',
    ticketTypes: [{ ...ticketType() }]
  }
}

export function event () {
  return {
    id: null,
    type: 'NORMAL',
    uid: Date.now(),
    header: null,
    title: null,
    subtitle: null,
    description: null,
    teaser: null,
    priceInfo: null,
    eventCategory: null,
    ticketAreas: [ticketArea()],
    venue: null,
    startDate: null,
    endDate: null,
    doorsOpen: null,
    doorsClosed: null,
    salesStartDate: null,
    salesEndDate: null,
    displayDate: null,
    vatRate: null,
    maxContingent: null,
    grossPrice: null,
    assets: [],
    eventSeriesId: null,
    eventSeriesData: null,
    questions: [],
    sesamMapping: null,
    seatmap: false,
    customTicketLayout: false,
    ticketText: null,
    keywords: [],
    showContingent: false,
    imagePlaceholder: true
  }
}

export function serie () {
  return {
    id: null,
    uid: Date.now(),
    title: null,
    description: null,
    teaser: null,
    priceInfo: null,
    eventCategory: null,
    ticketAreas: [ticketArea()],
    venue: null,
    ticketText: null,
    customTicketLayout: false,
    vatRate: null,
    maxContingent: null,
    grossPrice: null,
    assets: [],
    seriesType: null,
    type: 'NORMAL',
    startDateFrom: null,
    startDateTo: null,
    doorsOpenTime: null,
    doorsClosedTime: null,
    days: [],
    startTime: null,
    questions: [],
    imagePlaceholder: true
  }
}

export function geo () {
  return {
    latitude: null,
    longitude: null
  }
}

export function address () {
  return {
    street: null,
    city: null,
    zipCode: null,
    country: null,
    geoLocation: null
  }
}

export function venue () {
  return {
    id: null,
    name: null,
    status: null,
    address: address()
  }
}

export function sesamMappingValue () {
  return {
    id: null,
    areaId: null,
    status: null,
    capacity: null,
    // client only
    readonly: false
  }
}

export function sesamMapping () {
  return {
    id: null,
    version: null,
    name: null,
    values: null,
    hideSeatmap: false
  }
}

export function promotion () {
  return {
    id: null,
    affiliateId: null,
    affiliateName: null,
    name: null,
    validFrom: null,
    validTo: null,
    maxTickets: null,
    maxTicketsOrder: null,
    groupOf: null,
    // usedCodes: null,
    // totalCodes: null,
    // numBookedTickets: null,
    status: null,
    type: null,
    codes: null
  }
}

export function shopConfig () {
  return {
    title: null,
    description: null,
    primaryColor: '#002A54',
    bgColor: '#FFFFFF',
    font: 'Roboto',
    logoAssetId: [],
    questions: [],
    logoSize: null,
    affiliatePrivacy: null,
    robotsNoindex: false,
    reducedCustomerData: false,
    tracking: {
      analytics: null,
      ga4: null,
      meta: null
    }
  }
}

export function shopQuestion () {
  return {
    text: null,
    type: 'TEXT',
    active: false,
    answers: []
  }
}

export function eventQuestion () {
  return {
    text: null,
    type: 'TEXT',
    active: true,
    perTicket: true,
    mandatory: false,
    answers: [],
    prices: []
  }
}

export function answer () {
  return {
    text: null
  }
}

export function contact () {
  return {
    firstName: null,
    lastName: null,
    email: null,
    subject: null,
    text: null,
    recaptcha: null
  }
}
